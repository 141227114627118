export const restrictedCountries = [
  'BY',
  'MM',
  'CF',
  'CI',
  'CU',
  'CD',
  'IR',
  'IQ',
  'LR',
  'LY',
  'KP',
  'SD',
  'SO',
  'SY',
  'US',
  'VE',
  'YE',
  'ZW',
  'AL',
  'CN',
  'BA',
  'BG',
  'CR',
  'RS',
  'TW',
  'RO',
  'MK',
  'SI'
] as string[]
