


















































































import Vue, { PropType } from 'vue'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import { BigNumberInBase, BigNumberInWei, Status } from '@injectivelabs/utils'
import { BankBalanceWithTokenMetaData } from '~/types'
import {
  INJ_FEE_BUFFER,
  UI_DEFAULT_AMOUNT_DISPLAY_DECIMALS
} from '~/app/utils/constants'
import VSelectCustom from '~/components/inputs/select-custom.vue'

export default Vue.extend({
  components: {
    ValidationObserver,
    ValidationProvider,
    VSelectCustom
  },

  props: {
    balances: {
      required: true,
      type: Array as PropType<BankBalanceWithTokenMetaData[]>
    }
  },

  data() {
    return {
      status: new Status(),

      form: {
        amount: '',
        denom: 'inj',
        destination: ''
      }
    }
  },

  computed: {
    $form(): InstanceType<typeof ValidationObserver> {
      return this.$refs.form as InstanceType<typeof ValidationObserver>
    },

    balance(): BankBalanceWithTokenMetaData | undefined {
      const { balances, form } = this

      return balances.find((balance) => balance.denom === form.denom)
    },

    balanceToString(): string {
      const { balance } = this

      if (!balance) {
        return ''
      }

      return new BigNumberInWei(balance.balance)
        .toBase(balance.token.decimals)
        .minus(INJ_FEE_BUFFER)
        .toFixed(
          UI_DEFAULT_AMOUNT_DISPLAY_DECIMALS,
          BigNumberInBase.ROUND_FLOOR
        )
    }
  },

  methods: {
    onBlur() {
      const { form } = this

      this.form.amount = new BigNumberInBase(form.amount || 0).toFixed(
        UI_DEFAULT_AMOUNT_DISPLAY_DECIMALS
      )
    },

    handleTransferClick() {
      const { form, balance } = this

      if (!balance) {
        return
      }

      this.status.setLoading()

      this.$accessor.bank
        .transfer({
          token: balance.token,
          denom: form.denom,
          destination: form.destination,
          amount: new BigNumberInBase(form.amount)
        })
        .then(() => {
          this.$toast.success(this.$t('success_transfer_assets'))
          this.form.amount = ''
          this.form.destination = ''

          if (this.$form) {
            this.$form.reset()
          }
        })
        .catch(this.$onRejected)
        .finally(() => {
          this.status.setIdle()
        })
    }
  }
})
