













































































import Vue, { PropType } from 'vue'
import { uniqueId } from '~/app/utils/generators'
import { DOMEvent } from '~/types'

export default Vue.extend({
  inheritAttrs: false,

  props: {
    errors: {
      required: false,
      type: Array as PropType<string[]>,
      default: () => []
    },

    addon: {
      required: false,
      type: String,
      default: ''
    },

    lg: {
      required: false,
      type: Boolean,
      default: false
    },

    value: {
      required: true,
      type: [Object, String, Number],
      default: ''
    },

    label: {
      required: false,
      type: String,
      default: ''
    },

    minSelector: {
      required: false,
      type: Boolean,
      default: false
    },

    valid: {
      required: false,
      type: Boolean,
      default: false
    },

    maxSelector: {
      required: false,
      type: Boolean,
      default: false
    },

    customHandler: {
      required: false,
      type: Boolean,
      default: false
    }
  },

  computed: {
    uid(): string {
      return uniqueId()
    },

    validationClass(): { 'is-invalid': boolean; 'is-valid': boolean } | null {
      if (this.valid) {
        return {
          'is-invalid': false,
          'is-valid': true
        }
      }

      if (!this.valid && this.errors.length > 0) {
        return {
          'is-invalid': true,
          'is-valid': false
        }
      }

      return null
    },

    error(): string | null {
      if (this.errors.length > 0) {
        return this.errors[0]
      }

      return null
    }
  },

  methods: {
    handleManualChange() {
      const value = (this.$refs.input as HTMLInputElement).value

      if (value !== this.value) {
        this.$emit('input', value)
      }
    },

    handleChange(event: DOMEvent<HTMLSelectElement>) {
      this.$emit('input', event.target.value)
    },

    handleKeyUp(event: DOMEvent<HTMLSelectElement>) {
      this.$emit('keyup', event.target.value)
    },

    handleChangeFromString(value: string) {
      this.$emit('input', value)
    },

    handleMaxSelector() {
      const { maxSelector } = this
      const { max } = this.$attrs

      if (max || maxSelector) {
        if (!this.customHandler && max) {
          this.handleChangeFromString(max)
        }

        this.$emit('input-max')
      }
    },

    handleMinSelector() {
      const { minSelector } = this
      const { min } = this.$attrs

      if (min || minSelector) {
        if (!this.customHandler && min) {
          this.handleChangeFromString(min)
        }

        this.$emit('input-min')
      }
    }
  }
})
