




































































import Vue from 'vue'
import { directive as onClickaway } from 'vue-clickaway'
import VSpot from '~/components/partials/spot/markets/index-mobile.vue'
import VDerivatives from '~/components/partials/derivatives/markets/index-mobile.vue'
import Drawer from '~/components/elements/drawer.vue'

export default Vue.extend({
  components: {
    'v-drawer': Drawer,
    VSpot,
    VDerivatives
  },

  directives: {
    onClickaway
  },

  data() {
    return {
      isMenuOpen: false
    }
  },

  methods: {
    closeMenu() {
      if (this.isMenuOpen) {
        this.isMenuOpen = false
      }
    },

    toggleMenu() {
      this.isMenuOpen = !this.isMenuOpen
    }
  }
})
