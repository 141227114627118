


















































import Vue, { PropType } from 'vue'
import vSelect from 'vue-select'

export default Vue.extend({
  components: {
    vSelect
  },

  inheritAttrs: false,

  props: {
    errors: {
      required: false,
      type: Array as PropType<string[]>,
      default: () => []
    },

    customOptionSlot: {
      required: false,
      type: Boolean,
      default: false
    },

    addon: {
      required: false,
      type: String,
      default: ''
    },

    options: {
      required: true,
      type: Array as PropType<{ code: string; label: string }[]>
    },

    value: {
      required: true,
      type: [Object, String, Number],
      default: ''
    },

    label: {
      required: false,
      type: String,
      default: ''
    },

    valid: {
      required: false,
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      isDropdownOpen: false
    }
  },

  computed: {
    uid(): string {
      return Math.random().toString()
    },

    validationClass(): { 'is-invalid': boolean; 'is-valid': boolean } | null {
      if (this.valid) {
        return {
          'is-invalid': false,
          'is-valid': true
        }
      }
      if (!this.valid && this.errors.length > 0) {
        return {
          'is-invalid': true,
          'is-valid': false
        }
      }
      return null
    },

    error(): string | null {
      if (this.errors.length > 0) {
        return this.errors[0]
      }

      return null
    }
  },

  methods: {
    handleChange(value: string) {
      this.$emit('input', value || '')
    },

    handleDropdownButtonClick() {
      this.isDropdownOpen = !this.isDropdownOpen
    }
  }
})
