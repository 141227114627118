



















































import Vue from 'vue'
import VModalLedger from '~/components/wallets/ledger/index.vue'
import { Icon } from '~/types'

export default Vue.extend({
  components: {
    VModalLedger
  },

  data() {
    return {
      Icon,
      isLedgerModalOpen: false
    }
  },

  methods: {
    handleConnectClick() {
      this.isLedgerModalOpen = true
    }
  }
})
