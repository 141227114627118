var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"mb-6 px-4"},[_c('ValidationObserver',{ref:"form",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('div',{staticClass:"flex flex-wrap -mx-4"},[_c('div',{staticClass:"w-full px-4"},[_c('ValidationProvider',{attrs:{"name":"form.address","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('v-select-custom',{staticClass:"mt-2",attrs:{"errors":_vm.status.isLoading() ? [] : errors,"options":_vm.addresses.map(function (a) { return ({
                code: a,
                label: a
              }); }),"valid":valid,"label":_vm.$t('address'),"placeholder":_vm.$t('select_address_to_connect')},model:{value:(_vm.form.address),callback:function ($$v) {_vm.$set(_vm.form, "address", $$v)},expression:"form.address"}})]}}],null,true)})],1),_vm._v(" "),_c('div',{staticClass:"w-full px-4 mt-4"},[_c('v-ui-button',{attrs:{"status":_vm.status,"full":"","primary":!invalid,"ghost":invalid,"disabled":!_vm.form.address || invalid},on:{"click":function($event){$event.stopPropagation();return _vm.onConnect($event)}}},[_vm._v("\n          "+_vm._s(_vm.$t('connect'))+"\n        ")])],1)])]}}])}),_vm._v(" "),(_vm.status.isLoading())?_c('p',{staticClass:"text-gray-300 font-bold text-2xs mt-2"},[_vm._v("\n    "+_vm._s(_vm.$t('follow_instructions'))+"\n  ")]):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }