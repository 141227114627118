









































import Vue from 'vue'
import { Status } from '@injectivelabs/utils'
import { AccountAddress } from '@injectivelabs/ts-types'
import { LedgerDerivationPathType } from '@injectivelabs/web3-strategy'
import VSelectCustom from '~/components/inputs/select-custom.vue'

export default Vue.extend({
  components: {
    VSelectCustom
  },

  data() {
    return {
      status: new Status(),
      derivationPaths: [
        {
          label: this.$t('ledger_live'),
          path: LedgerDerivationPathType.LedgerLive
        },
        {
          label: this.$t('ledger_legacy'),
          path: LedgerDerivationPathType.LedgerMew
        }
      ],

      form: {
        path: LedgerDerivationPathType.LedgerLive,
        address: ''
      }
    }
  },

  computed: {
    addresses(): AccountAddress[] {
      return this.$accessor.wallet.addresses
    }
  },

  watch: {
    'form.path'(newValue: LedgerDerivationPathType) {
      this.$accessor.wallet.connectLedger({
        baseDerivationPath: newValue
      })
    }
  },

  methods: {
    onFetchAddresses() {
      this.status.setLoading()

      this.$accessor.wallet
        .getAddresses()
        .then((addresses: string[]) => {
          this.$accessor.wallet.setAddresses([...addresses, ...this.addresses])
        })
        .catch(this.$onRejected)
        .finally(() => {
          this.status.setIdle()
        })
    }
  }
})
