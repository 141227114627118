




















import Vue from 'vue'

export default Vue.extend({
  props: {
    shadow: {
      required: false,
      default: true,
      type: Boolean
    }
  }
})
