















































import Vue from 'vue'
import { Wallet } from '@injectivelabs/web3-strategy'
import { Icon } from '~/types'

export default Vue.extend({
  data() {
    return {
      Icon
    }
  },

  methods: {
    handleConnectClick() {
      this.$accessor.wallet
        .connectAndConfirm(Wallet.Metamask)
        .then(() => {
          this.$toast.success(this.$t('success_connect'))
        })
        .catch(this.$onRejected)
    }
  }
})
