var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('tr',{on:{"click":function($event){$event.stopPropagation();return _vm.onRowClick($event)}}},[_c("v-ui-table-td",{tag:"td"},[_c('div',{staticClass:"flex items-center"},[_c('img',{staticClass:"w-6 h-6 mr-4",attrs:{"src":_vm.market.baseToken.icon,"alt":_vm.market.baseToken.name}}),_vm._v(" "),_c('div',{staticClass:"leading-none"},[_c('p',{staticClass:"text-gray-100 font-semibold text-sm"},[_vm._v(_vm._s(_vm.market.ticker))]),_vm._v(" "),_c('p',{staticClass:"text-gray-500 text-xs"},[_vm._v("\n          "+_vm._s(_vm.market.baseToken.name)+"\n        ")])])])]),_vm._v(" "),_c("v-ui-table-td",{tag:"td",staticClass:"font-normal",attrs:{"right":""}},[(_vm.lastTradedPrice.gt(0))?_c('div',{staticClass:"flex justify-end items-center"},[_c('v-ui-format-price',_vm._b({staticClass:"mr-1"},'v-ui-format-price',{
          value: _vm.lastTradedPrice,
          decimals: _vm.market.priceDecimals,
          class: {
            'text-aqua-500': _vm.lastPriceChange === _vm.Change.Increase,
            'text-red-500': _vm.lastPriceChange === _vm.Change.Decrease
          }
        },false)),_vm._v(" "),([_vm.Change.Increase, _vm.Change.Decrease].includes(_vm.lastPriceChange))?_c('v-ui-icon',{attrs:{"xs":"","rotate":_vm.lastPriceChange === _vm.Change.Decrease,"aqua":_vm.lastPriceChange === _vm.Change.Increase,"red":_vm.lastPriceChange === _vm.Change.Decrease,"icon":_vm.Icon.Arrow}}):_vm._e()],1):_c('span',{staticClass:"text-gray-500"},[_vm._v("—")])]),_vm._v(" "),_c("v-ui-table-td",{tag:"td",staticClass:"font-normal",attrs:{"right":""}},[_c('v-ui-text',{staticClass:"text-right",attrs:{"sm":""}},[_c('v-ui-format-percent',_vm._b({},'v-ui-format-percent',{
          appendPlusSign: true,
          precision: 2,
          value: _vm.change.toFixed(),
          class: _vm.change.gte(0) ? 'text-aqua-500' : 'text-red-500'
        },false))],1)],1),_vm._v(" "),_c("v-ui-table-td",{tag:"td",staticClass:"font-normal",attrs:{"right":""}},[(_vm.volume.gt(0))?_c('v-ui-text',{staticClass:"flex items-center justify-end"},[_c('v-ui-format-number',_vm._b({},'v-ui-format-number',{
          dontGroupValues: true,
          value: _vm.volume,
          decimals: 0
        },false)),_vm._v(" "),_c('small',{staticClass:"text-xs font-normal text-gray-500 ml-1"},[_vm._v(_vm._s(_vm.market.quoteToken.symbol))])],1):_c('span',{staticClass:"text-gray-500"},[_vm._v("—")])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }