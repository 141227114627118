




















import Vue from 'vue'
import Form from './form.vue'
import ModalElement from '~/components/elements/modal.vue'
import { Modal } from '~/types/enums'
import { BankBalanceWithTokenMetaData } from '~/types'

export default Vue.extend({
  components: {
    modal: ModalElement,
    'v-form': Form
  },

  computed: {
    balances(): BankBalanceWithTokenMetaData[] {
      return this.$accessor.bank.balancesWithTokenMetaData
    },

    isModalOpen(): boolean {
      return this.$accessor.modal.modals[Modal.TransferOnChain]
    }
  },

  methods: {
    closeModal() {
      this.$accessor.modal.closeModal(Modal.TransferOnChain)
    }
  }
})
