







































































import Vue, { PropType } from 'vue'
import { BigNumberInBase } from '@injectivelabs/utils'
import { ZERO_IN_BASE } from '~/app/utils/constants'
import { UiSpotMarket, Icon, Change, UiSpotMarketSummary } from '~/types'

export default Vue.extend({
  props: {
    market: {
      required: true,
      type: Object as PropType<UiSpotMarket>
    },

    marketSummary: {
      required: true,
      type: Object as PropType<UiSpotMarketSummary>
    }
  },

  data() {
    return {
      Icon,
      Change
    }
  },

  computed: {
    currentMarket(): UiSpotMarket | undefined {
      return this.$accessor.spot.market
    },

    currentLastTradedPrice(): BigNumberInBase {
      return this.$accessor.spot.lastTradedPrice
    },

    lastTradedPrice(): BigNumberInBase {
      const {
        market,
        currentMarket,
        currentLastTradedPrice,
        marketSummary
      } = this

      if (!market || !marketSummary) {
        return ZERO_IN_BASE
      }

      if (
        currentMarket &&
        currentMarket.marketId === market.marketId &&
        currentLastTradedPrice
      ) {
        return currentLastTradedPrice
      }

      return new BigNumberInBase(marketSummary.price)
    },

    volume(): BigNumberInBase {
      const { market, marketSummary } = this

      if (!market || !marketSummary) {
        return ZERO_IN_BASE
      }

      return new BigNumberInBase(marketSummary.volume)
    },

    change(): BigNumberInBase {
      const { market, marketSummary } = this

      if (!market || !marketSummary) {
        return ZERO_IN_BASE
      }

      return new BigNumberInBase(marketSummary.change)
    },

    lastPriceChange(): Change {
      const { market, marketSummary } = this

      if (!market || !marketSummary) {
        return Change.NoChange
      }

      if (!marketSummary.lastPrice) {
        return Change.NoChange
      }

      if (
        new BigNumberInBase(marketSummary.lastPrice).eq(marketSummary.price)
      ) {
        return Change.NoChange
      }

      return new BigNumberInBase(marketSummary.price).gte(
        marketSummary.lastPrice
      )
        ? Change.Increase
        : Change.Decrease
    }
  },

  methods: {
    onRowClick() {
      const { market } = this

      this.$emit('selected')
      this.$router.push({
        name: 'spot-spot',
        params: {
          marketId: market.marketId,
          spot: market.slug
        }
      })
    }
  }
})
