














import Vue from 'vue'
import { Status } from '@injectivelabs/utils'
import { AccountAddress } from '@injectivelabs/ts-types'
import ModalElement from '~/components/elements/modal.vue'
import VLedgerAddressManager from '~/components/wallets/ledger/addresses.vue'
import VLedgerConfirm from '~/components/wallets/ledger/confirm.vue'

export default Vue.extend({
  components: {
    VLedgerAddressManager,
    VLedgerConfirm,
    modal: ModalElement
  },

  props: {
    isOpen: {
      required: true,
      type: Boolean
    }
  },

  data() {
    return {
      status: new Status(),
      addressStatus: new Status(),

      form: {
        address: ''
      }
    }
  },

  computed: {
    addresses(): AccountAddress[] {
      return this.$accessor.wallet.addresses
    }
  },

  mounted() {
    this.$accessor.wallet.setAddresses([])
    this.$accessor.wallet.connectLedger()
  },

  methods: {
    onClose() {
      if (this.status.isNotLoading()) {
        this.$emit('closed')
      }
    }
  }
})
