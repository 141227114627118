export default {
  address_copied: 'Address Copied',
  copy_address: 'Copy Address',
  max: 'Max',
  min: 'Min',
  injective: 'Injective',
  not_connected: 'Not Connected',
  connect: 'Connect',
  connect_to_wallet: 'Connect to wallet',
  connected: 'Connected',
  metamask: 'Metamask',
  spot_markets: 'Spot Markets',
  welcome_to_ip:
    'Access, create and trade unlimited decentralized finance markets',
  welcome_to_ip_sub:
    'Create any financial market on Injective’s fast, cross-chain, low fee, secure, and fully decentralized exchange protocol.',
  maintenance_header: 'Ongoing Maintenance',
  maintenance_subheader:
    'We are working on improving your experience. We will be back as soon as possible.',
  maintenance_body: '',
  token_allowance_successful: 'Token allowance set successful',
  logout: 'Logout',
  success_connect: 'Successfully Connected',
  market: 'Market',
  last_traded_price: 'Last Traded Price',
  last_traded_price_tooltip: 'Last Traded Price',
  market_change_24h: 'Change (24h)',
  market_volume_24h: 'Volume (24h)',
  filter_markets: 'Filter Markets',
  spot: 'Spot',
  subaccount_funds_available: 'Subaccount Funds',
  asset: 'Asset',
  balance: 'Balance',
  available: 'Available',
  deposit: 'Deposit',
  withdraw: 'Withdraw',
  transfer: 'Transfer',
  not_connected_balances: 'Connect your wallet to see your balances.',
  not_connect_orders: 'Connect your wallet to see your orders.',
  not_connect_trades: 'Connect your wallet to see your trades.',
  not_connected_trading: 'Connect your wallet to start trading',
  order_book: 'Orderbook',
  sum: 'Sum',
  price: 'Price',
  amount: 'Amount',
  total: 'Total',
  type: 'Type',
  side: 'Side',
  buy: 'Buy',
  sell: 'Sell',
  order_success_canceling: 'Order Cancelled',
  actions: 'Actions',
  trades: 'Trades',
  time: 'Time',
  size: 'Size',
  notional_size: 'Notional Size',
  notional_value: 'Notional Value',
  fee: 'Fee',
  direction: 'Direction',
  execution_type: 'Execution Type',
  open_orders: 'Open Orders',
  trade_history: 'Trade History',
  trading: 'Trading',
  buy_asset: 'Buy {asset}',
  sell_asset: 'Sell {asset}',
  long_asset: 'Long {asset}',
  short_asset: 'Short {asset}',
  limit: 'Limit',
  limit_buy: 'Limit Buy',
  limit_sell: 'Limit Sell',
  market_buy: 'Market Buy',
  market_sell: 'Market Sell',
  limit_long: 'Limit Long',
  limit_short: 'Limit Short',
  market_long: 'Market Long',
  market_short: 'Market Short',
  not_enough_fillable_orders: 'There are not enough orders to fill this amount',
  amount_decimals:
    'Amount <small style="opacity: 0.75;">({decimals} decimals)</small>',
  price_decimals:
    'Price <small style="opacity: 0.75;">({decimals} decimals)</small>',
  place_order: 'Place {type} order',
  order_placed: 'Your order has been placed',
  error_in_form: 'There are errors in your form',
  high_asset: 'High ({asset})',
  low_asset: 'Low ({asset})',
  volume_asset: '24h Volume ({asset})',
  high: '24h High',
  low: '24h Low',
  volume: 'Volume',
  price_chart: 'Price Chart',
  filled: 'Filled',
  cancel_order: 'Cancel Order',
  please_connect_your_wallet: 'Please connect with your wallet',
  amount_to_transfer: 'Amount to transfer',
  available_balance: 'Available Balance',
  transfer_modal_title: 'Deposit to Injective Chain',
  transfer_modal_note:
    "Trading on our Layer-2 Injective Chain requires you to transfer your balance to our Injective Chain. First, set allowance to the asset you want to transfer (if you haven't already), enter desired amount and complete your transfer",
  deposit_modal_title: 'Deposit to Subaccount',
  deposit_modal_note:
    'Your trading experience starts once you deposit your funds from the Injective Chain to your subaccount.',
  take_out_modal_title: 'Withdraw from Injective Chain',
  take_out_modal_note:
    'You can choose to withdraw your assets from the Injective Chain by filling up the form below',
  withdraw_modal_title: 'Withdraw from Subaccount',
  withdraw_modal_note:
    'You can always withdraw your funds back to the Injective Chain, so you can interact with the rest of the functionalities on the Injective Chain',
  transfer_asset: 'Deposit {asset}',
  deposit_asset: 'Deposit {asset}',
  withdraw_asset: 'Withdraw {asset}',
  take_out_asset: 'Withdraw {asset}',
  allowance_set: 'Allowance Set',
  set_allowance: 'Set Allowance',
  success_transfer: 'Successfully Deposited',
  success_transfer_assets: 'Successfully Transferred',
  trade_placed: 'Trade placed',
  success_deposit: 'Successfully Deposited',
  success_withdraw: 'Successfully Withdrawn',
  injective_chain_balance: 'Injective Chain Balances',
  balance_asset: 'Balance {asset}',
  unfilled: 'Unfilled',
  derivatives: 'Derivatives',
  long: 'Long',
  short: 'Short',
  max_leverage: 'Max Leverage:  {max}',
  margin: 'Margin',
  liquidation_price: 'Liquidation Price',
  positions: 'Positions',
  close_position: 'Close Position',
  entry_price: 'Entry Price',
  unrealized_pnl: 'Unrealized PnL',
  unrealized_pnl_tooltip:
    'The unrealized P&L is a reflection of what profit or loss will be realized if the position were to be closed now.',
  leverage: 'Leverage',
  not_available_n_a: 'N/A',
  position_closed: 'Position Closed',
  not_enough_balance: 'Not enough balance',
  reduce_only: 'Reduce Only',
  reduce_only_in_excess:
    'Total size of reduce-only orders would exceed size of your position',
  reduce_only_exceed_position:
    'Total size of reduce-only orders exceed the size of your position',
  worst_price_note:
    'Note: If {slippage}% max slippage protection is violated, you will need to resend your market order.',
  max_leverage_warn: 'Please decrease leverage.',
  next_funding: 'Next Funding',
  expiry_date: 'Expiry Date',
  days: 'Days',
  funding_fee: 'Funding fee',
  funding_rate: 'Funding Rate',
  est_funding_rate: 'Est. Funding Rate',
  funding_rate_tooltip:
    'The interest rate paid by position holders, determined by the difference between the perpetual swap price and underlying spot price. A positive funding rate implies that longs pay shorts, whereas a negative funding rate implies that shorts pay longs.',
  est_receiving_amount: 'Est. Receiving Amount',
  est_receiving_amount_note:
    'This amount is the least possible to be returned after the execution of the trade',
  fee_order_details_note:
    "If your limit order doesn't get filled as a taker order, you will only need to pay {feeReturned} in fees",
  buy_long: 'Buy/Long',
  sell_short: 'Sell/Short',
  mark_price: 'Mark Price',
  select_ledger_address: 'Select Ledger Address',
  follow_instructions: 'Please follow the instructions on your device',
  address: 'Address',
  select_address_to_connect: 'Select Address to Connect',
  derivation_path: 'Derivation Path',
  select_derivation_path: 'Select Derivation Path',
  get_addresses: 'Get Addresses',
  get_more_addresses: 'Get More Addresses',
  please_wait_addresses: 'We are getting your addresses, please wait ...',
  ledger_live: 'Ledger Live',
  ledger_legacy: 'Ledger Legacy',
  cancel_all: 'Cancel All',
  orders_cancelled: 'Orders Cancelled',
  yes: 'Yes',
  no: 'No',
  not_valid_number: 'Not a valid number',
  mark_price_invalid: 'The mark price is not valid',
  order_insufficient_margin: 'Order has insufficient margin',
  date: 'Date',
  small_bridge_fee_note: 'Bridge fee: {fee} {asset}',
  small_gas_fee_note: 'Gas: {fee} {asset}',
  add_margin_to_position_title: 'Add Margin',
  add_margin_to_position_note:
    'You can increase the margin your position has to prevent liquidation',
  add_margin: 'Add Margin',
  success_added_margin: 'You have successfully added margin to your position',
  no_liquidity: 'Not enough Liquidity',
  close_auto_liquidation:
    'Closing this position with current market depth would result in auto-liquidation.',
  execution_price_surpasses_bankruptcy_price:
    'Execution price surpasses bankruptcy price',
  you_can_only_have_max_orders:
    'You can only have {number} orders per side per market per subaccount',
  transfer_on_chain_title: 'Transfer on Injective Chain',
  transfer_on_chain_note:
    'Transfer your assets to an address on the Injective Chain',
  select_asset: 'Select Asset',
  destination: 'Destination',
  disclaimer_note:
    'By connecting a wallet, you agree to the Injective Labs <a href="https://injectivelabs.org/terms-and-conditions" class="text-primary-500" target="_blank" />Terms and Conditions</a> and acknowledge that you have read and understand the Injective Protocol <a href="https://injectiveprotocol.com/disclaimer" class="text-primary-500" target="_blank">disclaimer</a>.',
  transfer_wait_time_note:
    'Note: It should take around 4 minutes for your transfer to appear after your transaction has been confirmed on Ethereum.'
}
