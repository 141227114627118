var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('tr',{on:{"click":function($event){$event.stopPropagation();return _vm.onRowClick($event)}}},[_c("v-ui-table-td",{tag:"td",attrs:{"xs":""}},[_c('p',{staticClass:"text-gray-100 text-sm"},[_vm._v(_vm._s(_vm.market.ticker))])]),_vm._v(" "),_c("v-ui-table-td",{tag:"td",attrs:{"right":"","xs":""}},[(_vm.lastTradedPrice.gt(0))?_c('div',{staticClass:"flex justify-end items-center"},[_c('v-ui-format-price',_vm._b({staticClass:"mr-1"},'v-ui-format-price',{
          value: _vm.lastTradedPrice,
          decimals: _vm.market.priceDecimals,
          class: {
            'text-aqua-500': _vm.lastPriceChange === _vm.Change.Increase,
            'text-red-500': _vm.lastPriceChange === _vm.Change.Decrease
          }
        },false)),_vm._v(" "),([_vm.Change.New, _vm.Change.NoChange].includes(_vm.lastPriceChange))?_c('v-ui-icon',{attrs:{"xs":"","rotate":_vm.lastPriceChange === _vm.Change.Decrease,"aqua":_vm.lastPriceChange === _vm.Change.Increase,"red":_vm.lastPriceChange === _vm.Change.Decrease,"icon":_vm.Icon.Arrow}}):_vm._e()],1):_c('span',{staticClass:"text-gray-500"},[_vm._v("—")])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }