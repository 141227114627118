























































































import Vue from 'vue'
import { directive as onClickaway } from 'vue-clickaway'
import Item from './item.vue'
import Logo from '~/components/layouts/logo.vue'
import { localStorage } from '~/app/singletons/Storage'
import { locales, Locale } from '~/locales'
import { Icon } from '~/types'

export default Vue.extend({
  directives: {
    onClickaway
  },

  components: {
    Item,
    Logo
  },

  data() {
    return {
      Icon,
      locales,
      showLocaleDropdown: false
    }
  },

  computed: {
    visibleLocales(): Locale[] {
      return this.locales.filter(({ locale }: Locale) => {
        return !this.$i18n.locale.includes(locale)
      })
    }
  },

  methods: {
    goHome() {
      this.$router.push({ name: 'index' })
    },

    onToggleLocaleDropdown() {
      this.showLocaleDropdown = !this.showLocaleDropdown
    },

    onCloseLocaleDropdown() {
      if (this.showLocaleDropdown) {
        this.showLocaleDropdown = false
      }
    },

    changeLocale(locale: Locale) {
      localStorage.set('locale', locale.locale)
      this.$i18n.locale = locale.locale
      this.$accessor.app.setAppLocale(locale)
      this.onCloseLocaleDropdown()
    }
  }
})
