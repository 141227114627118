


















import Vue from 'vue'
import Derivative from './derivative-mobile.vue'
import {
  UiDerivativeMarket,
  UiDerivativeMarketSummary,
  UiDerivativeMarketAndSummary
} from '~/types'

export default Vue.extend({
  components: {
    'v-derivative': Derivative
  },

  computed: {
    markets(): UiDerivativeMarket[] {
      return this.$accessor.derivatives.markets
    },

    marketsSummary(): UiDerivativeMarketSummary[] {
      return this.$accessor.derivatives.marketsSummary
    },

    mappedMarkets(): UiDerivativeMarketAndSummary[] {
      const { markets, marketsSummary } = this

      return markets
        .map((market) => {
          return {
            market,
            summary: marketsSummary.find(
              (summary) => summary.marketId === market.marketId
            )
          }
        })
        .filter(
          ({ summary }) => summary !== undefined
        ) as UiDerivativeMarketAndSummary[]
    }
  }
})
