














































import Vue from 'vue'
import VSpot from './spot.vue'
import VDerivatives from './derivatives.vue'
import Logo from '~/components/layouts/logo.vue'
import MobileMenu from '~/components/layouts/mobile/menu.vue'
import WalletConnect from '~/components/wallets/index.vue'

export default Vue.extend({
  components: {
    Logo,
    VSpot,
    VDerivatives,
    MobileMenu,
    WalletConnect
  },

  computed: {
    //
  },

  methods: {
    goHome() {
      this.$router.push({ name: 'index' })
    }
  }
})
