


























































































































import Vue from 'vue'
import { AccountAddress } from '@injectivelabs/ts-types'
import { Wallet } from '@injectivelabs/web3-strategy'
import { directive as onClickaway } from 'vue-clickaway'
import { formatWalletAddress } from '@injectivelabs/utils'
import VDisclaimer from './disclaimer.vue'
import VMetamask from './wallets/metamask.vue'
import VLedger from './wallets/ledger.vue'
import { TRANSFER_RESTRICTIONS_ENABLED } from '~/app/utils/constants'
import { Icon, Modal } from '~/types'

export default Vue.extend({
  components: {
    VMetamask,
    VLedger,
    VDisclaimer
  },

  directives: {
    onClickaway
  },

  data() {
    return {
      TRANSFER_RESTRICTIONS_ENABLED,
      isDropdownOpen: false,
      Wallet,
      Icon
    }
  },

  computed: {
    isUserWalletConnected(): boolean {
      return this.$accessor.wallet.isUserWalletConnected
    },

    address(): AccountAddress {
      return this.$accessor.wallet.address
    },

    injectiveAddress(): AccountAddress {
      return this.$accessor.wallet.injectiveAddress
    },

    wallet(): Wallet {
      return this.$accessor.wallet.wallet
    },

    formattedAddress(): string {
      const { injectiveAddress } = this

      return formatWalletAddress(injectiveAddress)
    }
  },

  methods: {
    closeDropdown() {
      if (this.isDropdownOpen) {
        this.isDropdownOpen = false
      }
    },

    toggleDropdown() {
      this.isDropdownOpen = !this.isDropdownOpen
    },

    onLogoutClick() {
      this.$accessor.wallet.logout()
    },

    onTransferNavClick() {
      this.$accessor.modal.openModal(Modal.TransferOnChain)
    }
  }
})
