






















































import Vue from 'vue'
import Derivative from './derivative.vue'
import {
  UiDerivativeMarket,
  Icon,
  UiDerivativeMarketSummary,
  UiDerivativeMarketAndSummary
} from '~/types'

export default Vue.extend({
  components: {
    'v-derivative': Derivative
  },

  data() {
    return {
      Icon,
      filterMarkets: ''
    }
  },

  computed: {
    markets(): UiDerivativeMarket[] {
      return this.$accessor.derivatives.markets
    },

    marketsSummary(): UiDerivativeMarketSummary[] {
      return this.$accessor.derivatives.marketsSummary
    },

    filteredMarkets(): UiDerivativeMarketAndSummary[] {
      const { filterMarkets, markets, marketsSummary } = this

      const query = filterMarkets.toLowerCase()

      return markets
        .map((market) => {
          return {
            market,
            summary: marketsSummary.find(
              (summary) => summary.marketId === market.marketId
            )
          }
        })
        .filter(({ market, summary }) => {
          const { ticker, quoteDenom } = market
          const satisfiesSearchCondition =
            quoteDenom.toLowerCase().startsWith(query) ||
            ticker.toLowerCase().startsWith(query)

          return satisfiesSearchCondition && summary !== undefined
        }) as UiDerivativeMarketAndSummary[]
    }
  }
})
