






















































import Vue from 'vue'
import Spot from './spot.vue'
import {
  UiSpotMarket,
  UiSpotMarketSummary,
  UiSpotMarketAndSummary,
  Icon
} from '~/types'

export default Vue.extend({
  components: {
    'v-spot': Spot
  },

  data() {
    return {
      filterMarkets: '',
      Icon
    }
  },

  computed: {
    markets(): UiSpotMarket[] {
      return this.$accessor.spot.markets
    },

    marketsSummary(): UiSpotMarketSummary[] {
      return this.$accessor.spot.marketsSummary
    },

    filteredMarkets(): UiSpotMarketAndSummary[] {
      const { filterMarkets, markets, marketsSummary } = this

      const query = filterMarkets.toLowerCase()

      return markets
        .map((market) => {
          return {
            market,
            summary: marketsSummary.find(
              (summary) => summary.marketId === market.marketId
            )
          }
        })
        .filter(({ market, summary }) => {
          const { ticker, baseDenom, quoteDenom } = market
          const satisfiesSearchCondition =
            baseDenom.toLowerCase().startsWith(query) ||
            quoteDenom.toLowerCase().startsWith(query) ||
            ticker.toLowerCase().startsWith(query)

          return satisfiesSearchCondition && summary !== undefined
        }) as UiSpotMarketAndSummary[]
    }
  }
})
