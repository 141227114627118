

























































import Vue from 'vue'

export default Vue.extend({
  props: {
    isOpen: {
      required: true,
      type: Boolean
    },

    close: {
      required: false,
      default: true,
      type: Boolean
    }
  },

  /**
   * We close the modal on ESC
   */
  mounted() {
    const onEscape = (e: KeyboardEvent) => {
      if (this.isOpen && this.close && e.keyCode === 27) {
        this.onClose()
      }
    }

    document.addEventListener('keydown', onEscape)

    this.$once('hook:destroyed', () => {
      document.removeEventListener('keydown', onEscape)
    })
  },

  methods: {
    onClose() {
      this.$emit('closed')
    }
  }
})
