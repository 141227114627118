
























import Vue from 'vue'
import { Icon } from '~/types'

export default Vue.extend({
  props: {
    customHandler: {
      required: false,
      type: Boolean,
      default: false
    },

    customIsOpen: {
      required: false,
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      Icon,
      isOpen: false
    }
  },

  computed: {
    drawerIsOpen(): boolean {
      return this.customIsOpen || this.isOpen
    }
  },

  methods: {
    toggle() {
      if (this.customHandler) {
        this.$emit('drawer-toggle')
        return
      }
      this.isOpen = !this.isOpen
    }
  }
})
