var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"p-4"},[_c('div',{staticClass:"px-2"},[_c('v-select-custom',{staticClass:"mb-8",attrs:{"options":_vm.balances.map(function (a) { return ({
          code: a.denom,
          label: a.token.symbol
        }); }),"label":_vm.$t('asset'),"placeholder":_vm.$t('select_asset')},model:{value:(_vm.form.denom),callback:function ($$v) {_vm.$set(_vm.form, "denom", $$v)},expression:"form.denom"}}),_vm._v(" "),(_vm.form.denom && _vm.balanceToString)?[_c('v-ui-text-info',{attrs:{"title":_vm.$t('available_balance')}},[(_vm.balanceToString)?_c('span',[_vm._v(_vm._s(_vm.balanceToString))]):_c('span',{staticClass:"text-gray-400 font-normal text-xs"},[_vm._v("—")])]),_vm._v(" "),_c('ValidationObserver',{ref:"form",scopedSlots:_vm._u([{key:"default",fn:function(ref){
        var invalid = ref.invalid;
return [_c('div',{staticClass:"mb-4"},[_c('ValidationProvider',{attrs:{"name":"form.amount","rules":("required|positiveNumber|between:0.0001," + _vm.balanceToString)},scopedSlots:_vm._u([{key:"default",fn:function(ref){
        var errors = ref.errors;
        var valid = ref.valid;
return [_c('v-input',{staticClass:"mt-4",attrs:{"errors":_vm.status.isLoading() ? [] : errors,"valid":valid,"max":_vm.balanceToString,"max-selector":!!_vm.balanceToString,"placeholder":_vm.$t('amount_to_transfer'),"type":"number","step":"0.0001","min":"0"},on:{"blur":_vm.onBlur},model:{value:(_vm.form.amount),callback:function ($$v) {_vm.$set(_vm.form, "amount", $$v)},expression:"form.amount"}},[_c('span',{attrs:{"slot":"addon"},slot:"addon"},[_vm._v(_vm._s(_vm.balance ? _vm.balance.token.symbol : ''))])])]}}],null,true)})],1),_vm._v(" "),_c('div',[_c('ValidationProvider',{attrs:{"name":"form.destination","rules":"required|injaddress"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
        var errors = ref.errors;
        var valid = ref.valid;
return [_c('v-input',{attrs:{"errors":_vm.status.isLoading() ? [] : errors,"valid":valid,"placeholder":_vm.$t('destination')},model:{value:(_vm.form.destination),callback:function ($$v) {_vm.$set(_vm.form, "destination", $$v)},expression:"form.destination"}})]}}],null,true)})],1),_vm._v(" "),_c('div',{staticClass:"w-full mx-auto mt-4"},[_c('v-ui-button',{attrs:{"status":_vm.status,"full":"","primary":!invalid,"ghost":invalid,"disabled":!_vm.form.amount || !_vm.form.destination || invalid},on:{"click":function($event){$event.stopPropagation();return _vm.handleTransferClick($event)}}},[_vm._v("\n            "+_vm._s(_vm.$t('transfer'))+"\n          ")])],1)]}}],null,false,2919005586)})]:_vm._e()],2)])}
var staticRenderFns = []

export { render, staticRenderFns }